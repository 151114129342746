import { func, string } from 'prop-types';
import React, { useState } from 'react';
import Modal from 'react-responsive-modal';
import { useTranslation } from 'react-i18next';
import './DeleteDialog.scss';

const DeleteDialog = ({ contentKey, onDelete, onClose }) => {
  const [open, setOpen] = useState(true);

  const { t } = useTranslation();

  const onConfirm = () => {
    setOpen(false);
    if (onDelete) {
      onDelete();
    }
  };

  const onCancel = () => {
    setOpen(false);
    if (onClose) {
      onClose();
    }
  };

  return (
    <Modal
      open={open}
      center
      onClose={onCancel}
      classNames={{
        overlay: 'customOverlay',
        modal: 'customModal',
        closeButton: 'customCloseButton',
      }}
    >
      <div className="side-bar-menu-settings-table">
        <div>
          <b>
            <span>{t(contentKey)}</span>
          </b>
        </div>
        <div>
          <input
            name="save"
            type="submit"
            onClick={onConfirm}
            className="yr-button button-error"
            value={t('button.delete')}
          />
          <input
            id="delete"
            name="cancel"
            type="button"
            onClick={onCancel}
            className="yr-button"
            value={t('button.cancel')}
          />
        </div>
      </div>
    </Modal>
  );
};

DeleteDialog.propTypes = {
  contentKey: string.isRequired,
  onDelete: func.isRequired,
  onClose: func.isRequired,
};

export default DeleteDialog;
