import { arrayOf, func, shape, string } from 'prop-types';
import React, { useState, useEffect } from 'react';
import './Select.scss';

export const Select = ({ options, selectedValue, onChange }) => {
  const [open, setOpen] = useState(false);
  const [currentValue, setCurrentValue] = useState(selectedValue);

  const handleInputClick = () => {
    setOpen((previousValue) => !previousValue);
  };
  const handleClick = (option) => {
    setCurrentValue(() => option.value);
    handleInputClick();
    if (onChange) {
      onChange(option.value);
    }
  };

  useEffect(() => {
    setCurrentValue(selectedValue);
  }, [selectedValue]);

  const getCurrentLabel = () => {
    const found = options.find((it) => it.value === currentValue);
    return found ? found.label : undefined;
  };

  return (
    <div data-testid="dropdown" className="select">
      <div
        data-testid="dropdown_input"
        aria-hidden="true"
        role="button"
        className="select-input"
        onClick={handleInputClick}
      >
        <div>{getCurrentLabel()}</div>
        <div>
          <i className="icon-up-down f-small" />
        </div>
      </div>

      {open && (
        <div data-testid="dropdown_values" className="select-values">
          {options.map((it) => (
            <div
              key={it.value}
              aria-hidden="true"
              role="button"
              className="select-value"
              onClick={() => handleClick(it)}
            >
              {it.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

Select.propTypes = {
  options: arrayOf(
    shape({
      label: string,
      value: string,
    })
  ).isRequired,
  selectedValue: string.isRequired,
  onChange: func.isRequired,
};
