import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import React from 'react';
import ReactDOM from 'react-dom';
import { ToastContainer } from 'react-toastify';
import App from './App';
import { AuthProvider } from './components/Authentication';
import initI18n from './services/i18n';
import 'react-toastify/dist/ReactToastify.css';
import 'react-responsive-modal/styles.css';
import './styles/style.scss';

const sentryDsn = `${process.env.REACT_APP_SENTRY_DSN}`;
const sentryEnv = `${process.env.REACT_APP_SENTRY_ENV}`;
const version = `${process.env.REACT_APP_VERSION}`;
const enableSentry =
  sentryDsn &&
  sentryDsn.length > 0 &&
  sentryDsn !== 'undefined' &&
  sentryEnv &&
  sentryEnv.length > 0 &&
  sentryEnv !== 'undefined';
if (enableSentry) {
  Sentry.init({
    dsn: sentryDsn,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    release: version,
    environment: sentryEnv,
  });
}

const CloseButton = () => <div>&times;</div>;

initI18n();

ReactDOM.render(
  <AuthProvider>
    <App />
    <ToastContainer
      toastClassName="toast-custom"
      hideProgressBar
      closeButton={<CloseButton />}
      pauseOnHover={false}
      autoClose={3000}
      icon={false}
      theme="colored"
    />
  </AuthProvider>,
  document.getElementById('root')
);
