import { bool } from 'prop-types';
import React from 'react';
import './LoaderOverlay.scss';

const LoaderOverlay = ({ busy }) => {
  const isBusy = () => busy;

  return (
    isBusy() && (
      <div data-testid="loader" id="overlay">
        <div id="spinner-container">
          <div id="spinner" className="simple-loader">
            &nbsp;
          </div>
        </div>
      </div>
    )
  );
};

LoaderOverlay.propTypes = {
  busy: bool.isRequired,
};

export default LoaderOverlay;
