import React, { useEffect } from 'react';
import { useTable, usePagination } from 'react-table';
import { useTranslation } from 'react-i18next';

export const FeedbackTable = ({
  setCurrentPage,
  setLimit,
  columns,
  data,
  loading,
  limit,
  currentPage,
  pageCount: controlledPageCount,
}) => {
  const { t } = useTranslation();
  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    headerGroups,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: currentPage, pageSize: limit },
      manualPagination: true,
      pageCount: controlledPageCount,
    },
    usePagination
  );

  // get offset and limit values to update graphqlQuery whenever these values change
  useEffect(() => {
    setCurrentPage(pageIndex);
    setLimit(pageSize);
  }, [pageIndex, pageSize, setLimit, setCurrentPage]);

  const handleGoToPage = (e) => {
    const upcomingPage = e.target.value ? Number(e.target.value) - 1 : 0;
    gotoPage(upcomingPage);
  };

  return (
    <div className="table-container">
      <div className="pagination">
        <span>
          {t('feedback.show')}:
          <select
            className="pagination-select"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[5, 10, 20, 50].map((currentPageSize) => (
              <option key={currentPageSize} value={currentPageSize}>
                {currentPageSize} {t('feedback.rows')}
              </option>
            ))}
          </select>
        </span>
        <span>
          <button
            type="button"
            onClick={previousPage}
            disabled={!canPreviousPage}
            className="pagination-button"
          >
            {'<'}
          </button>
          <span className="pagination-page-container">
            {t('feedback.page')} <strong>{pageIndex + 1} </strong>
            {t('feedback.of')} {pageOptions.length}
          </span>
          <button
            type="button"
            onClick={nextPage}
            disabled={!canNextPage}
            className="pagination-button"
          >
            {'>'}
          </button>
        </span>
        <span className="pagination-go-to">
          {t('feedback.go.to.page')}:
          <input
            type="number"
            className="pagination-go-to-input"
            defaultValue={pageIndex + 1}
            onChange={handleGoToPage}
          />
        </span>
      </div>
      <table {...getTableProps()} className="table">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps({
                    style: { width: column.width },
                  })}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} className="table-loading">
          {loading && (
            <tr className="table-loading-container">
              <td className="table-loading-text">{t('feedback.loading')}</td>
            </tr>
          )}
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td
                      {...cell.getCellProps(({ key }) => {
                        // align only "Rating" cells to right
                        return {
                          style: {
                            textAlign:
                              key && key.includes('rating') ? 'right' : 'left',
                          },
                        };
                      })}
                    >
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>

      {/* if no data, show simple message to the user */}
      {!loading && !page.length && (
        <p className="table-no-data">{t('feedback.no.data')}</p>
      )}
    </div>
  );
};
