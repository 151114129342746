import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthContext } from './components/Authentication';
import SideBarMenu from './components/SideBarMenu';
import Dashboard from './components/Dashboard';
import Media from './components/Media';
import Email from './components/Email';
import User from './components/User';
import RoomType from './components/RoomType';
import LanguageSetting from './components/LanguageSetting';
import Form from './components/Form';
import Export from './components/Export';
import Sync from './components/Sync';
import SessionConnector from './components/SessionConnector';
import { Feedback } from './components/Feedback';
import './App.scss';

const App = () => {
  const [selectedItem, setSelectedItem] = useState('dashboard');
  const authCtx = useContext(AuthContext);
  const { t } = useTranslation();

  const onItemSelected = (item) => {
    setSelectedItem(item);
  };

  const onCloseLanguageSetting = () => {
    setSelectedItem(undefined);
  };

  const renderAppAuth = () => {
    return (
      <div className="app">
        <SideBarMenu onItemSelected={onItemSelected} />
        <div className="app-main">
          <div data-testid="admin_main" className="app-main-content">
            {selectedItem === 'dashboard' && <Dashboard />}
            {selectedItem === 'media' && <Media />}
            {selectedItem === 'form' && <Form />}
            {selectedItem === 'roomType' && <RoomType />}
            {selectedItem === 'email' && <Email />}
            {selectedItem === 'user' && <User />}
            {selectedItem === 'export' && <Export />}
            {selectedItem === 'feedback' && <Feedback />}
            {selectedItem === 'sessionConnector' && <SessionConnector />}
            {selectedItem === 'sync' && <Sync />}
            {selectedItem === 'admin' && (
              <LanguageSetting onClose={onCloseLanguageSetting} />
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderAppNotAuth = () => (
    <div className="app">
      <div className="app-main">
        <div className="app-main-content">
          <div>{t('error.unauthorized')}</div>
        </div>
      </div>
    </div>
  );

  return authCtx && authCtx.isAuthenticated
    ? renderAppAuth()
    : renderAppNotAuth();
};

export default App;
