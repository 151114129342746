import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { AuthContext } from './Authentication';
import LoaderOverlay from './LoaderOverlay';
import { Select } from './Select';
import { getRoomTypesQuery } from '../services/Queries';

const User = () => {
  const authContext = useContext(AuthContext);
  const [options, setOptions] = useState([]);
  const { handleSubmit, reset } = useForm();
  const { t } = useTranslation();

  const [busy, setBusy] = useState(false);
  const [selectedUser, setSelectedUser] = useState(undefined);
  const [unauthorised, setUnauthorised] = useState(false);
  const [impersonation, setImpersonation] = useState(false);
  const [users, setUsers] = useState([]);

  const DEFAULT_ROOM_TYPE_KEY = 'ysura_defaultRoomTypeId';

  useEffect(() => {
    setBusy(true);
    authContext
      .graphQLClient()
      .query({
        query: getRoomTypesQuery(),
      })
      .then((result) => {
        const roomOptions = result.data.roomTypes.data.map((it) => ({
          value: it.id,
          label: it.name,
        }));
        roomOptions.unshift({
          value: null,
          label: '-',
        });
        setOptions(roomOptions);
      });
    if (
      authContext.tokenParsed?.resource_access[
        'realm-management'
      ]?.roles?.includes('impersonation')
    ) {
      setImpersonation(true);
    }

    axios({
      url: `${process.env.REACT_APP_AUTH_URL}/admin/realms/${authContext.keycloakRealm}/users/count`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${authContext.token}`,
      },
    })
      .then((result) =>
        axios({
          url: `${process.env.REACT_APP_AUTH_URL}/admin/realms/${authContext.keycloakRealm}/users`,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${authContext.token}`,
          },
          params: {
            max: result.data,
          },
        })
      )
      .then((response) => {
        const respondedUsers = [...response.data];
        respondedUsers.sort((a, b) => {
          if (a?.lastName?.toUpperCase() < b?.lastName?.toUpperCase()) {
            return -1;
          }
          if (a?.lastName?.toUpperCase() > b?.lastName?.toUpperCase()) {
            return 1;
          }
          return 0;
        });
        setUsers(respondedUsers);
        setUnauthorised(false);
      })
      .catch(() => setUnauthorised(true))
      .finally(() => setBusy(false));
  }, [authContext]);

  const onUserSelected = (it) => {
    if (it.attributes && it.attributes[DEFAULT_ROOM_TYPE_KEY]) {
      setSelectedUser({
        ...it,
        [DEFAULT_ROOM_TYPE_KEY]: it.attributes[DEFAULT_ROOM_TYPE_KEY][0],
      });
    } else {
      setSelectedUser(it);
    }
  };

  const renderUserItem = (it) => (
    <div
      key={it.id}
      aria-hidden="true"
      role="button"
      tabIndex={0}
      className="list-item list-item-with-shadow"
      onClick={() => onUserSelected(it)}
    >
      {selectedUser && selectedUser.id === it.id && (
        <div className="list-item-selected" />
      )}
      <div className="list-item-title">{`${it?.lastName ?? '-'}, ${
        it?.firstName ?? '-'
      }`}</div>
    </div>
  );

  const renderUserList = () => (
    <section data-testid="admin_user" className="column-stretch h100">
      {unauthorised && (
        <label htmlFor="user">{t('user.queryUser.unauthorised')}</label>
      )}
      {!unauthorised && (
        <>
          <div>
            <label htmlFor="user">{t('user.current')}</label>
            {authContext.tokenParsed && authContext.tokenParsed.name}
          </div>
          <div className="overflow-y-auto fix-margin-shadow">
            {users.map(renderUserItem)}
          </div>
        </>
      )}
    </section>
  );

  const handleRoomTypeChange = (value) => {
    selectedUser[DEFAULT_ROOM_TYPE_KEY] = value;
  };

  const onSave = () => {
    const found = options.find(
      (it) => selectedUser[DEFAULT_ROOM_TYPE_KEY] === it.value
    );
    if (found) {
      if (!selectedUser.attributes) {
        selectedUser.attributes = {};
      }
      selectedUser.attributes[DEFAULT_ROOM_TYPE_KEY] = [
        selectedUser[DEFAULT_ROOM_TYPE_KEY],
      ];
    }
    axios({
      url: `${process.env.REACT_APP_AUTH_URL}/admin/realms/${authContext.keycloakRealm}/users/${selectedUser.id}`,
      method: 'PUT',
      contentType: 'application/json',
      headers: {
        Authorization: `Bearer ${authContext.token}`,
      },
      data: {
        attributes: selectedUser.attributes,
      },
    })
      .then(() => {
        toast(t('user.save.success'), { type: toast.TYPE.SUCCESS });
      })
      .catch(() => {
        toast(t('user.save.error'), { type: toast.TYPE.ERROR });
      });
    setSelectedUser(undefined);
  };

  const onCancel = () => {
    selectedUser[DEFAULT_ROOM_TYPE_KEY] = undefined;
    setSelectedUser(undefined);
    reset();
  };

  const onImpersonate = () => {
    axios({
      url: `${process.env.REACT_APP_AUTH_URL}/admin/realms/${authContext.keycloakRealm}/users/${selectedUser.id}/impersonation`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${authContext.token}`,
      },
      withCredentials: true,
    }).catch(() => {
      // we do it inside catch block because `withCredentials: true` will not return us 200,
      // but it will make a trick and did not relocate to auth url and keep us in a session but with new credentials
      const url = window.location.href;
      window.location.replace(
        window.location.hostname === 'localhost'
          ? 'http://localhost:3000/#/lobby/'
          : `${url.replace('admin-', '')}#/lobby/`
      );
    });
  };

  const renderUserDetails = () => (
    <section className="column-stretch w100">
      <form onSubmit={handleSubmit(onSave)}>
        <label htmlFor="username">{t('user.username')}</label>
        <div id="username">
          <input
            name="username"
            type="text"
            value={selectedUser.username || ''}
            disabled
          />
        </div>

        <label htmlFor="firstName">{t('user.firstName')}</label>
        <div id="firstName">
          <input
            name="firstName"
            type="text"
            value={selectedUser.firstName || ''}
            disabled
          />
        </div>

        <label htmlFor="lastName">{t('user.lastName')}</label>
        <div id="lastName">
          <input
            name="lastName"
            type="text"
            value={selectedUser.lastName || ''}
            disabled
          />
        </div>

        <label htmlFor="email">{t('user.email')}</label>
        <div id="email">
          <input
            name="email"
            type="text"
            value={selectedUser.email || ''}
            disabled
          />
        </div>

        <label htmlFor="roomType">{t('user.roomType')}</label>
        <div id="roomType">
          <Select
            options={options}
            selectedValue={
              selectedUser.attributes &&
              selectedUser.attributes[DEFAULT_ROOM_TYPE_KEY]
                ? selectedUser.attributes[DEFAULT_ROOM_TYPE_KEY][0]
                : null
            }
            onChange={handleRoomTypeChange}
          />
        </div>

        <div>
          <input
            name="save"
            type="submit"
            className="yr-button"
            value={t('button.save')}
          />
          <input
            name="cancel"
            type="button"
            onClick={onCancel}
            className="yr-button button-error"
            value={t('button.cancel')}
          />
          {impersonation && (
            <input
              name="impersonate"
              type="button"
              onClick={onImpersonate}
              className="yr-button"
              value={t('button.impersonate')}
            />
          )}
        </div>
      </form>
    </section>
  );

  return (
    <>
      <LoaderOverlay busy={busy} />
      {users && renderUserList()}
      {selectedUser && renderUserDetails()}
    </>
  );
};

export default User;
