import React, { useContext, useEffect, useState } from 'react';
import './Sync.scss';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { AuthContext } from './Authentication';
import LoaderOverlay from './LoaderOverlay';
import {
  getRealmConfigurationQuery,
  getSyncMutation,
} from '../services/Queries';
import DeleteDialog from './DeleteDialog';

const Sync = () => {
  const authContext = useContext(AuthContext);
  const { t } = useTranslation();

  const [realmConfiguration, setRealmConfiguration] = useState(undefined);
  const [busy, setBusy] = useState(false);
  const [confirmation, setConfirmation] = useState(false);

  useEffect(() => {
    setBusy(true);
    authContext
      .graphQLClient()
      .query({
        query: getRealmConfigurationQuery(),
      })
      .then((result) =>
        setRealmConfiguration(result.data.getRealmConfiguration)
      )
      .finally(() => setBusy(false));
  }, [authContext]);

  const onSync = () => {
    setBusy(true);
    authContext
      .graphQLClient()
      .mutate({
        mutation: getSyncMutation(),
      })
      .then((result) => {
        if (result.data) {
          toast(t('sync.completed'), { type: toast.TYPE.SUCCESS });
        } else {
          toast(t('sync.notCompleted'), { type: toast.TYPE.WARNING });
        }
      })
      .finally(() => setBusy(false));
  };

  return (
    <>
      <LoaderOverlay busy={busy} />
      <section data-testid="admin_sync" className="column-stretch w100">
        <div className="sync-desc">{t('sync.description')}</div>
        {realmConfiguration && realmConfiguration.upstream && (
          <>
            <div>
              {t('sync.upstream')}{' '}
              <span className="sync-desc">{realmConfiguration.upstream}</span>
            </div>
            <div>
              <input
                name="sync"
                type="button"
                onClick={() => setConfirmation(true)}
                className="yr-button button-error"
                value={t('sync.button')}
              />
            </div>
          </>
        )}
        {realmConfiguration && !realmConfiguration.upstream && (
          <div className="sync-error">{t('sync.noUpstream')}</div>
        )}
        {confirmation && (
          <DeleteDialog
            contentKey="sync.deleteConfirm"
            onDelete={onSync}
            onClose={() => setConfirmation(false)}
          />
        )}
      </section>
    </>
  );
};

export default Sync;
