import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { AuthContext } from './Authentication';
import LoaderOverlay from './LoaderOverlay';
import {
  getMediaAndRoomTypesQuery,
  getUpdateMediaMutation,
  getAddMediaMutation,
  getDeleteMediaMutation,
  getDocumentQuery,
} from '../services/Queries';
import uploadFile from '../services/S3FileUpload';
import './Media.scss';
import DeleteDialog from './DeleteDialog';

const Media = () => {
  const authContext = useContext(AuthContext);
  const { register, handleSubmit, reset, errors } = useForm();

  const { t } = useTranslation();

  const [version, setVersion] = useState(0);
  const [busy, setBusy] = useState(false);
  const [media, setMedia] = useState();
  const [roomTypes, setRoomTypes] = useState();
  const [selectedMedia, setSelectedMedia] = useState();
  const [deleteDialog, setDeleteDialog] = useState(false);

  useEffect(() => {
    setBusy(true);
    authContext
      .graphQLClient()
      .query({
        query: getMediaAndRoomTypesQuery(),
      })
      .then((result) => {
        setMedia(result.data.media.data);
        setRoomTypes(result.data.roomTypes.data);
      })
      .finally(() => setBusy(false));
  }, [version, authContext]);

  const mediaTypeIcon = (mediaType) => {
    switch (mediaType) {
      case 'text/html':
        return 'icon-file-html';
      case 'application/pdf':
        return 'icon-file-pdf';
      default:
    }
    return 'icon-file';
  };

  const onMediaSelected = (it) => {
    setSelectedMedia(it);
    reset();
  };

  const onCreateMedia = () => {
    setSelectedMedia({});
    reset();
  };

  const openFile = (id) => {
    authContext
      .graphQLClient()
      .query({
        query: getDocumentQuery(id),
      })
      .then((result) => {
        if (
          result.data &&
          result.data.document &&
          result.data.document.downloadUrl
        ) {
          window.open(result.data.document.downloadUrl);
        }
      });
  };

  const onSave = (data) => {
    const selectedName = data.name;
    const selectedRoomTypes = roomTypes
      .filter((rt) => data[rt.id])
      .map((rt) => rt.id);
    const selectedThumbnail =
      data.thumbnail.length > 0 ? data.thumbnail[0] : undefined;
    const selectedContent =
      data.content.length > 0 ? data.content[0] : undefined;

    if (selectedMedia.id) {
      // Update the media on the server
      const mutation = getUpdateMediaMutation(
        selectedMedia.id,
        selectedName,
        selectedRoomTypes,
        selectedMedia.thumbnailDocument.id,
        selectedThumbnail,
        selectedMedia.contentDocument.id,
        selectedContent
      );
      authContext
        .graphQLClient()
        .mutate({
          mutation,
        })
        .then((result) => {
          const fileUploads = [];
          if (selectedThumbnail) {
            fileUploads.push(
              uploadFile(
                result.data.updateMedia.thumbnailDocument.id,
                selectedThumbnail,
                authContext
              )
            );
          }
          if (selectedContent) {
            fileUploads.push(
              uploadFile(
                result.data.updateMedia.contentDocument.id,
                selectedContent,
                authContext
              )
            );
          }
          return Promise.all(fileUploads);
        })
        .then(() => {
          toast(t('media.message.save'), { type: toast.TYPE.SUCCESS });
          setVersion(version + 1);
          setSelectedMedia(undefined);
        })
        .finally(() => setBusy(false));
    } else {
      // Create a new media on the server
      const mutation = getAddMediaMutation(
        selectedName,
        selectedRoomTypes,
        selectedThumbnail,
        selectedContent
      );
      authContext
        .graphQLClient()
        .mutate({
          mutation,
        })
        .then((result) => {
          const fileUploads = [
            uploadFile(
              result.data.addMedia.thumbnailDocument.id,
              selectedThumbnail,
              authContext
            ),
            uploadFile(
              result.data.addMedia.contentDocument.id,
              selectedContent,
              authContext
            ),
          ];
          return Promise.all(fileUploads);
        })
        .then(() => {
          setVersion(version + 1);
          setSelectedMedia(undefined);
        })
        .finally(() => setBusy(false));
    }
  };

  const onCancel = () => {
    if (!selectedMedia.id) {
      setSelectedMedia(undefined);
      reset();
    } else {
      onMediaSelected(selectedMedia);
      reset();
    }
  };

  const toggleDeleteDialog = () => {
    setDeleteDialog(!deleteDialog);
  };

  const onDelete = () => {
    if (selectedMedia.id) {
      const mutation = getDeleteMediaMutation(selectedMedia.id);
      authContext
        .graphQLClient()
        .mutate({
          mutation,
        })
        .then((result) => {
          if (result.data && result.data.deleteMedia) {
            toast(t('media.message.delete'), { type: toast.TYPE.SUCCESS });
            setVersion(version + 1);
            setSelectedMedia(undefined);
          } else {
            toast(t('media.message.delete.error'), { type: toast.TYPE.ERROR });
          }
        })
        .finally(() => setBusy(false));
      setDeleteDialog(false);
    }
  };

  const renderMediaItem = (it) => (
    <div
      key={it.id}
      aria-hidden="true"
      role="button"
      className="list-item list-item-with-shadow"
      onClick={() => onMediaSelected(it)}
    >
      {selectedMedia && selectedMedia.id === it.id && (
        <div className="list-item-selected" />
      )}
      <div className="list-item-title">
        <i className={mediaTypeIcon(it.contentDocument.mediaType)} /> {it.name}
      </div>
    </div>
  );

  const renderMediaList = () => (
    <section data-testid="admin_media" className="column-stretch h100">
      <div className="list-item">
        <div
          className="list-item-title clickable"
          aria-hidden="true"
          role="button"
          onClick={onCreateMedia}
        >
          <i className="icon-circled-plus icon-small clickable" />{' '}
          {t('media.add.new')}
        </div>
      </div>
      <div className="overflow-y-auto fix-margin-shadow">
        {media.map(renderMediaItem)}
      </div>
    </section>
  );

  const renderRoomTypeItem = (it) => {
    const { id } = it;
    const checked =
      selectedMedia.roomTypes && selectedMedia.roomTypes.includes(id);
    return (
      <div key={id}>
        <input
          name={id}
          type="checkbox"
          defaultChecked={checked}
          ref={register}
        />
        {it.name}
      </div>
    );
  };

  const renderMediaEditor = () => (
    <section data-testid="admin_media_section" className="column-stretch w100">
      <form onSubmit={handleSubmit(onSave)}>
        <label htmlFor="name">{t('common.name')}</label>
        <div id="name">
          <input
            name="name"
            type="text"
            defaultValue={selectedMedia.name || ''}
            ref={register({ required: true })}
          />
          {errors.name && (
            <span className="error-desc">{t('common.name.error')}</span>
          )}
        </div>

        <div>&nbsp;</div>

        <label htmlFor="thumbnail">{t('media.thumbnail')}</label>
        <div id="thumbnail" className="document">
          {selectedMedia.thumbnailDocument && (
            <div
              aria-hidden="true"
              role="button"
              className="document-box cursor"
              onClick={() => openFile(selectedMedia.thumbnailDocument.id)}
            >
              <img src={selectedMedia.thumbnailDocument.downloadUrl} alt="" />
            </div>
          )}
          <input
            name="thumbnail"
            type="file"
            accept="image/png, image/jpeg"
            ref={register({ required: !selectedMedia.thumbnailDocument })}
          />
          {errors.thumbnail && (
            <span className="error-desc">{t('media.thumbnail.error')}</span>
          )}
        </div>

        <div>&nbsp;</div>

        <label htmlFor="content">{t('media.content')}</label>
        <div id="content" className="document">
          {selectedMedia.contentDocument && (
            <div
              aria-hidden="true"
              role="button"
              className="document-box"
              onClick={() => openFile(selectedMedia.contentDocument.id)}
            >
              <i
                className={mediaTypeIcon(
                  selectedMedia.contentDocument.mediaType
                )}
              />
            </div>
          )}
          <input
            name="content"
            type="file"
            accept="text/html, application/pdf"
            ref={register({ required: !selectedMedia.contentDocument })}
          />
          {errors.content && (
            <span className="error-desc">{t('media.content.error')}</span>
          )}
        </div>

        <div>&nbsp;</div>

        <label htmlFor="roomTypes">{t('media.roomType.title')}</label>
        <div id="roomTypes">{roomTypes.map(renderRoomTypeItem)}</div>

        <div>
          <input
            name="save"
            type="submit"
            className="yr-button"
            value={t('button.save')}
          />
          <input
            name="cancel"
            type="button"
            onClick={onCancel}
            className="yr-button button-error"
            value={t('button.cancel')}
          />
          {selectedMedia.id && (
            <input
              name="delete"
              type="button"
              onClick={toggleDeleteDialog}
              className="yr-button button-error"
              value={t('button.delete')}
            />
          )}
        </div>
      </form>
      {deleteDialog && (
        <DeleteDialog
          contentKey="media.message.delete.confirmation"
          onDelete={onDelete}
          onClose={toggleDeleteDialog}
        />
      )}
    </section>
  );

  return (
    <>
      <LoaderOverlay busy={busy} />
      {media && roomTypes && renderMediaList()}
      {selectedMedia && renderMediaEditor()}
    </>
  );
};

export default Media;
