import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getRealmPropertySessionConnectorQuery } from '../services/Queries';
import LoaderOverlay from './LoaderOverlay';
import { AuthContext } from './Authentication';
import YsuraSessionConnector from './YsuraSessionConnector';

const SessionConnector = () => {
  const authContext = useContext(AuthContext);
  const { t } = useTranslation();
  const [busy, setBusy] = useState(false);
  const [connector, setConnector] = useState();

  useEffect(() => {
    setBusy(true);
    authContext
      .graphQLClient()
      .query({
        query: getRealmPropertySessionConnectorQuery(),
      })
      .then((result) =>
        result.data && result.data.sessionConnector
          ? setConnector(JSON.parse(result.data.sessionConnector))
          : setConnector('NONE')
      )
      .finally(() => setBusy(false));
  }, [authContext]);

  return (
    <div data-testid="admin_connector">
      <LoaderOverlay busy={busy} />
      {connector === 'NONE' && <div>{t('connector.none')}</div>}
      {connector === 'YSURA' && <YsuraSessionConnector />}
    </div>
  );
};

export default SessionConnector;
