import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { de, enGB } from 'date-fns/locale';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import { changeLocale } from './date-fns';

const initI18n = () => {
  registerLocale('de-DE', de);
  registerLocale('en', enGB);

  i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      resources: {
        en: {
          translations: {
            generalError: 'An unknown error occurred.',
            generalNetworkError: 'A network error occurred.',
            'button.cancel': 'Cancel',
            'button.delete': 'Delete',
            'button.save': 'Save',
            'button.impersonate': 'Impersonate',
            'common.name': 'Name',
            'common.name.error': '* A name is required.',
            'common.time': 'Time',
            'common.to': 'to',
            'common.no': 'No ',
            'dashboard.title': 'Administration',
            'dashboard.systemStatus.title': 'System Status',
            'dashboard.systemStatus.contentServer': 'Content Server',
            'dashboard.systemStatus.stateServer': 'State Server',
            'error.unauthorized': 'You are not authorized to use this app.',
            'export.data.formData': 'Form',
            'export.data.sessionEvent': 'Session Event',
            'export.data.title': 'Export CSV Data',
            'form.add.new': 'Add a new form',
            'form.data.download.dialog.title': 'Please select the time range',
            'form.data.download.error': 'Failed to export for {{item}}',
            'form.data.download.success': 'Succeed to export for {{item}}',
            'form.message.delete': 'Form was deleted.',
            'form.message.delete.confirmation':
              'Form is about to be deleted. Are you sure?',
            'form.message.delete.error': 'Form cannot be deleted.',
            'form.message.save': 'Form was saved.',
            'form.template.title': 'Template',
            'form.template.error': '* A template is required.',
            'media.add.new': 'Add new media',
            'media.content': 'Content',
            'media.content.error': '* A content document is required.',
            'media.message.delete': 'Media was deleted.',
            'media.message.delete.confirmation':
              'Media is about to be deleted. Are you sure?',
            'media.message.delete.error': 'Media cannot be deleted.',
            'media.message.save': 'Media was saved.',
            'media.thumbnail': 'Thumbnail',
            'media.thumbnail.error': '* A thumbnail is required.',
            'media.roomType.title': 'Associated room types',
            'roomType.add.new': 'Add new room type',
            'roomType.defaultType.title': 'Default type',
            'roomType.defaultType.error':
              'Only one room type can be set as default type',
            'roomType.screenSharingEnable.title': 'Enable screen sharing',
            'roomType.message.delete': 'Room type was deleted',
            'roomType.message.delete.confirmation':
              'Room type is about to be deleted. Are you sure?',
            'roomType.message.delete.error': 'Room type cannot be deleted',
            'roomType.message.save': 'Room type was saved.',
            settings: 'Settings',
            'settings.language': 'Language',
            'settings.language.title': 'Language settings',
            'settings.user.email': 'Use user\'s email as "from" address',
            'settings.user.email.reply':
              'Use user\'s email as "reply-to" address',
            'settings.user.email.test': 'Send a test mail',
            'settings.user.template': 'Use custom template',
            'settings.user.template.title': 'Template',
            'sync.completed': 'The sync has completed.',
            'sync.notCompleted': 'The sync was not started.',
            'sync.description':
              "The data of the current system is replaced by those of the upstream system. This action can't be reverted.",
            'sync.noUpstream':
              'A sync is not possible as no upstream system is defined.',
            'sync.upstream': 'Upstream system: ',
            'sync.button': 'Start sync',
            'sync.deleteConfirm':
              'All current data will be deleted and overwritten. Are you sure?',
            'connector.ysura.activityType': 'Activity Type',
            'connector.ysura.topic': 'Topic',
            'connector.ysura.commentType': 'Comment Type',
            'connector.ysura.mediaMapping': 'Media Mapping',
            'connector.ysura.consentTypeMapping': 'Form Consent Mapping',
            'connector.ysura.save': 'The settings were saved.',
            'connector.none':
              'This system is not configured to use a connector.',
            'user.username': 'Username',
            'user.firstName': 'First Name',
            'user.lastName': 'Last Name',
            'user.email': 'Email',
            'user.roomType': 'Assigned room type',
            'user.save.success': 'The user settings were saved.',
            'user.save.error': 'Failed to save the user settings.',
            'user.queryUser.unauthorised':
              'You are not authorized to manage users.',
            'user.current': 'Current user: ',
            'feedback.title': 'Call feedback',
            'feedback.total.number': 'Total number of feedback provided',
            'feedback.average.rating': 'Average feedback rating',
            'feedback.page': 'Page',
            'feedback.of': 'of',
            'feedback.go.to.page': 'Go to page',
            'feedback.rows': 'rows',
            'feedback.show': 'Show',
            'feedback.column.roomId': 'yRoom ID',
            'feedback.column.date': 'Date',
            'feedback.column.rating': 'Rating',
            'feedback.column.message': 'Feedback',
            'feedback.loading': 'Loading...',
            'feedback.no.data': 'No data',
          },
        },
        de: {
          translations: {
            generalError: 'Ein unbekannter Fehler ist aufgetreten.',
            generalNetworkError: 'Ein Netzwerkfehler ist aufgetreten.',
            'button.cancel': 'Abbrechen',
            'button.delete': 'Löschen',
            'button.save': 'Speichern',
            'button.impersonate': 'Benutzer wechseln',
            'common.name': 'Name',
            'common.name.error': '* Name ist erforderlich.',
            'common.time': 'Zeit',
            'common.to': 'bis',
            'common.no': 'Kein ',
            'dashboard.title': 'Admin',
            'dashboard.systemStatus.title': 'Systemstatus',
            'dashboard.systemStatus.contentServer': 'Inhaltsserver',
            'dashboard.systemStatus.stateServer': 'Statusserver',
            'error.unauthorized':
              'Sie sind nicht berechtigt, diese App zu verwenden.',
            'export.data.formData': 'Formular',
            'export.data.sessionEvent': 'Sitzungsereignisse',
            'export.data.title': 'CSV-Daten exportieren',
            'form.add.new': 'Neues Formular hinzufügen',
            'form.data.download.dialog.title': 'Bitte wählen Sie den Zeitraum',
            'form.data.download.error':
              'Exportieren für {{item}} is fehlgeschlagen',
            'form.data.download.success':
              'Exportieren für {{item}} is erfolgreich',
            'form.message.delete': 'Formular wurde gelöscht.',
            'form.message.delete.confirmation':
              'Formular wird gelöscht. Sind Sie sicher?',
            'form.message.delete.error': 'Formular kann nicht gelöscht werden.',
            'form.message.save': 'Formular wurde gespeichert.',
            'form.template.title': 'Vorlage',
            'form.template.error': '* Ein Vorlage ist erforderlich.',
            'media.add.new': 'Neue Medien hinzufügen',
            'media.content': 'Inhalt',
            'media.content.error': 'Ein Inhaltsdokument ist erforderlich.',
            'media.message.delete': 'Medium wurden gelöscht.',
            'media.message.delete.confirmation':
              'Medium wird gelöscht. Sind Sie sicher?',
            'media.message.delete.error': 'Medium kann nicht gelöscht werden.',
            'media.message.save': 'Medium wurden gespeichert.',
            'media.thumbnail': 'Miniaturansicht',
            'media.thumbnail.error': '* Ein Miniaturansicht ist erforderlich.',
            'media.roomType.title': 'Zugehörige Raumtypen',
            'roomType.add.new': 'Neuen Raumtyp hinzufügen',
            'roomType.defaultType.title': 'Standardtyp',
            'roomType.defaultType.error':
              'Es kann nur ein Raumtyp als Standardtyp festgelegt werden',
            'roomType.screenSharingEnable.title':
              'Bildschirmfreigabe aktivieren',
            'roomType.message.delete': 'Raumtyp wurden gelöscht.',
            'roomType.message.delete.confirmation':
              'Raumtyp wird gelöscht. Sind Sie sicher?',
            'roomType.message.delete.error':
              'Raumtyp kann nicht gelöscht werden.',
            'roomType.message.save': 'Raumtyp wurden gespeichert.',
            settings: 'Einstellung',
            'settings.language': 'Sprache',
            'settings.language.title': 'Spracheinstellungen',
            'settings.user.email':
              'E-Mail-Adresse des Benutzers als "Von" -Adresse verwenden',
            'settings.user.email.reply':
              'E-Mail-Adresse des Benutzers als Antwortadresse verwenden',
            'settings.user.email.test': 'Testmail senden',
            'settings.user.template': 'Benutzerdefinierte Vorlage verwenden',
            'settings.user.template.title': 'Vorlage',
            'sync.completed': 'Die Synchronisation wurde ausgeführt.',
            'sync.notCompleted':
              'Die Synchronisation konnte nicht ausgeführt werden.',
            'sync.description':
              'Die Daten des aktuellen Systems werden mit denen des Upstream-Systems ersetzt. Diese Aktion kann nicht rückgängig gemacht werden.',
            'sync.noUpstream':
              'Eine Synchronisations ist nicht möglich, da kein Upstream-System bekannt ist.',
            'sync.upstream': 'Upstream-System: ',
            'sync.button': 'Syncronisation starten',
            'sync.deleteConfirm':
              'Alle Daten werden gelöscht und überschrieben. Sind Sie sicher?',
            'connector.ysura.activityType': 'Aktivitätstyp',
            'connector.ysura.topic': 'Thema',
            'connector.ysura.commentType': 'Kommentartyp',
            'connector.ysura.mediaMapping': 'Medium-Zuweisungen',
            'connector.ysura.consentTypeMapping':
              'Formular-Consent-Zuweisungen',
            'connector.ysura.save': 'Die Einstellungen wurden gespeichert.',
            'connector.none': 'Dieses System verwendet keinen Konnektor.',
            'user.username': 'Benutzer',
            'user.firstName': 'Vorname',
            'user.lastName': 'Nachname',
            'user.email': 'E-Mail',
            'user.roomType': 'Zugewiesener Raumtyp',
            'user.save.success':
              'Die Benutzereinstellungen wurden gespeichert.',
            'user.save.error':
              'Die Benutzereinstellungen konnten nicht gespeichert werden.',
            'user.queryUser.unauthorised':
              'Sie sind nicht berechtigt Benutzer zu verwalten.',
            'user.current': 'Aktueller Benutzer: ',
            'feedback.title': 'Übersicht Bewertungen',
            'feedback.total.number': 'Gesamtzahl der Bewertungen',
            'feedback.average.rating': 'Durchschnittliche Bewertung',
            'feedback.page': 'Seite',
            'feedback.of': 'von',
            'feedback.go.to.page': 'Weiter zur Seite',
            'feedback.rows': 'Zeilen',
            'feedback.show': 'Anzeige',
            'feedback.column.roomId': 'yRoom ID',
            'feedback.column.date': 'Datum',
            'feedback.column.rating': 'Bewertung',
            'feedback.column.message': 'Kommentar',
            'feedback.loading': 'Laden...',
            'feedback.no.data': 'Keine Daten',
          },
        },
      },
      fallbackLng: 'en',
      debug: false,

      // have a common namespace used around the full app
      ns: ['translations'],
      defaultNS: 'translations',

      keySeparator: false, // we use content as keys

      interpolation: {
        escapeValue: false,
      },
    });

  i18n.on('languageChanged', (language) => {
    changeLocale(language);
    setDefaultLocale(language);
  });

  changeLocale(i18n.language);
  setDefaultLocale(i18n.language);
};

export default initI18n;
