import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { toast } from 'react-toastify';
import DateTimePickDialog from './DateTimePickDialog';
import { AuthContext } from './Authentication';

const Export = () => {
  const authContext = useContext(AuthContext);

  const { t } = useTranslation();

  const [selectedItem, setSelectedItem] = useState(false);

  const [dateTimeDialog, setDateTimeDialog] = useState(false);

  const toggleExportDialog = () => {
    setDateTimeDialog(!dateTimeDialog);
  };

  const items = [
    {
      id: 'form-data',
      name: 'formData',
      url: '/formInstance/download',
      function: toggleExportDialog,
    },
    {
      id: 'session-event',
      name: 'sessionEvent',
      url: '/sessionEvent/download',
      function: toggleExportDialog,
    },
  ];

  const onItemSelected = (it) => {
    setSelectedItem(it);
    if (it.function) {
      it.function();
    }
  };

  const onDownloadData = (startDate, endDate) => {
    axios({
      url: process.env.REACT_APP_API_URL + selectedItem.url,
      method: 'GET',
      responseType: 'blob',
      headers: {
        'X-Ysura-Realm': authContext.realm,
        Authorization: `Bearer ${authContext.token}`,
      },
      params: {
        from: startDate ? startDate.toISOString() : undefined,
        to: endDate ? endDate.toISOString() : undefined,
      },
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const dateString = new Date().toISOString().slice(0, 10);
        const fileName = `${selectedItem.name}.${dateString}.csv`;
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
      })
      .then(() => {
        toast(
          t('form.data.download.success', {
            item: t(`export.data.${selectedItem.name}`),
          }),
          { type: toast.TYPE.SUCCESS }
        );
        setSelectedItem(undefined);
      })
      .catch(() => {
        toast(
          t('form.data.download.error', {
            item: t(`export.data.${selectedItem.name}`),
          }),
          { type: toast.TYPE.ERROR }
        );
      });
    toggleExportDialog();
  };

  const renderItem = (it) => (
    <div
      key={it.id}
      aria-hidden="true"
      role="button"
      className="list-item list-item-with-shadow"
      onClick={() => onItemSelected(it)}
    >
      {selectedItem && selectedItem.id === it.id && (
        <div className="list-item-selected" />
      )}
      <div className="list-item-title">{t(`export.data.${it.name}`)}</div>
    </div>
  );

  return (
    <section data-testid="admin_export" className="column-stretch h100">
      <div className="list-item">
        <div className="list-item-title">
          <span className="icon-small" /> {t('export.data.title')}
        </div>
      </div>
      <div className="overflow-y-auto fix-margin-shadow">
        {items.map(renderItem)}
      </div>
      {dateTimeDialog && (
        <DateTimePickDialog
          title="form.data.download.dialog.title"
          interval={15}
          onSave={onDownloadData}
          onClose={toggleExportDialog}
        />
      )}
    </section>
  );
};

export default Export;
