import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {
  getYsuraSessionConnectorMutation,
  getYsuraSessionConnectorQuery,
} from '../services/Queries';
import LoaderOverlay from './LoaderOverlay';
import { AuthContext } from './Authentication';
import './YsuraSessionConnector.scss';
import { Select } from './Select';

const YsuraSessionConnector = () => {
  const authContext = useContext(AuthContext);
  const { handleSubmit, reset } = useForm();
  const { t } = useTranslation();

  const [version, setVersion] = useState(0);
  const [busy, setBusy] = useState(true);
  const [media, setMedia] = useState([]);
  const [forms, setForms] = useState([]);
  const [data, setData] = useState();
  const [selectedActivityType, setSelectedActivityType] = useState(undefined);
  const [selectedTopic, setSelectedTopic] = useState(undefined);
  const [selectedCommentType, setSelectedCommentType] = useState(undefined);
  const [selectedMediaMapping, setSelectedMediaMapping] = useState({});
  const [selectedConsentTypeMapping, setSelectedConsentTypeMapping] = useState(
    {}
  );

  useEffect(() => {
    setBusy(true);
    authContext
      .graphQLClient()
      .query({
        query: getYsuraSessionConnectorQuery(),
      })
      .then((result) => {
        setMedia(result.data.media.data);
        setForms(result.data.form.data);
        setData(JSON.parse(result.data.sessionConnectorConfigData.data));
        setSelectedActivityType(JSON.parse(result.data.activityType));
        setSelectedTopic(JSON.parse(result.data.topic));
        setSelectedCommentType(JSON.parse(result.data.commentType));

        const mmIn = result.data.mediaMapping;
        const mediaMapping =
          (mmIn && mmIn.trim().length > 0 && JSON.parse(mmIn)) || {};
        setSelectedMediaMapping(mediaMapping);

        const ctmIn = result.data.consentTypeMapping;
        const consentTypeMapping =
          (ctmIn && ctmIn.trim().length > 0 && JSON.parse(ctmIn)) || {};
        setSelectedConsentTypeMapping(consentTypeMapping);
      })
      .finally(() => {
        setBusy(false);
      });
  }, [authContext, version]);

  const handleSelection = (name, value, subItem) => {
    if (name === 'activityType') {
      setSelectedActivityType(value);
    } else if (name === 'topic') {
      setSelectedTopic(value);
    } else if (name === 'commentType') {
      setSelectedCommentType(value);
    } else if (name === 'media') {
      if (value) {
        selectedMediaMapping[subItem] = value;
      } else delete selectedMediaMapping[subItem];
      setSelectedMediaMapping(selectedMediaMapping);
    } else if (name === 'consent') {
      if (value) {
        selectedConsentTypeMapping[subItem] = value;
      } else delete selectedConsentTypeMapping[subItem];
      setSelectedConsentTypeMapping(selectedConsentTypeMapping);
    }
  };

  const onSave = () => {
    setBusy(true);
    const activityType = selectedActivityType
      ? JSON.stringify(selectedActivityType)
      : null;
    const topic = selectedTopic ? JSON.stringify(selectedTopic) : null;
    const commentType = selectedCommentType
      ? JSON.stringify(selectedCommentType)
      : null;
    const mediaMapping = JSON.stringify(selectedMediaMapping);
    const consentTypeMapping = JSON.stringify(selectedConsentTypeMapping);
    authContext
      .graphQLClient()
      .mutate({
        variables: {
          activityType,
          topic,
          commentType,
          mediaMapping,
          consentTypeMapping,
        },
        mutation: getYsuraSessionConnectorMutation(),
      })
      .then(() =>
        toast(t('connector.ysura.save'), { type: toast.TYPE.SUCCESS })
      )
      .finally(() => {
        setBusy(false);
        setVersion((prev) => prev + 1);
      });
  };

  const onCancel = () => {
    reset();
    setVersion((prev) => prev + 1);
  };

  const renderSelect = (name, items, selectedValue, subItem) => {
    const options = items.map((it) => ({
      value: it.id,
      label: it.displayName,
    }));
    options.unshift({
      value: null,
      label: '-',
    });
    return (
      <Select
        options={options}
        selectedValue={selectedValue}
        onChange={(value) => handleSelection(name, value, subItem)}
      />
    );
  };

  const renderMappingTable = (name, items, mappedItems, selectedMapping) => (
    <table className="mapping-table">
      <tbody>
        {items.map((it) => (
          <tr key={it.id}>
            <td>{it.name}</td>
            <td>
              {renderSelect(
                name,
                mappedItems,
                selectedMapping && selectedMapping[it.id],
                it.id
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  return (
    <>
      <LoaderOverlay busy={busy} />
      {data && !busy && (
        <section
          data-testid="admin_media_section"
          className="column-stretch w100"
        >
          <form onSubmit={handleSubmit(onSave)}>
            <label htmlFor="activityType">
              {t('connector.ysura.activityType')}
            </label>
            <div id="activityType">
              {renderSelect(
                'activityType',
                data.activityTypes,
                selectedActivityType
              )}
            </div>
            <div>&nbsp;</div>
            <label htmlFor="topic">{t('connector.ysura.topic')}</label>
            <div id="topic">
              {renderSelect('topic', data.topics, selectedTopic)}
            </div>
            <div>&nbsp;</div>
            <label htmlFor="commentType">
              {t('connector.ysura.commentType')}
            </label>
            <div id="commentType">
              {renderSelect(
                'commentType',
                data.commentTypes,
                selectedCommentType
              )}
            </div>
            {media && media.length > 0 && (
              <>
                <div>&nbsp;</div>
                <label htmlFor="mediaMapping">
                  {t('connector.ysura.mediaMapping')}
                </label>
                <div id="mediaMapping">
                  {renderMappingTable(
                    'media',
                    media,
                    data.media,
                    selectedMediaMapping
                  )}
                </div>
              </>
            )}
            {forms && forms.length > 0 && (
              <>
                <div>&nbsp;</div>
                <label htmlFor="formMapping">
                  {t('connector.ysura.consentTypeMapping')}
                </label>
                <div id="formMapping">
                  {renderMappingTable(
                    'consent',
                    forms,
                    data.consentTypes,
                    selectedConsentTypeMapping
                  )}
                </div>
              </>
            )}
            <div>&nbsp;</div>
            <div>
              <input
                name="save"
                type="submit"
                className="yr-button"
                value={t('button.save')}
              />
              <input
                name="cancel"
                type="button"
                onClick={onCancel}
                className="yr-button button-error"
                value={t('button.cancel')}
              />
            </div>
          </form>
        </section>
      )}
    </>
  );
};

export default YsuraSessionConnector;
