import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { AuthContext } from './Authentication';
import LoaderOverlay from './LoaderOverlay';
import {
  getRealmPropertyEmailQuery,
  getSendLinkMailTestMutation,
  setRealmPropertyEmailMutation,
} from '../services/Queries';
import './Email.scss';

const Email = () => {
  const authContext = useContext(AuthContext);
  const { register, handleSubmit, reset, getValues } = useForm();

  const { t } = useTranslation();

  const [version, setVersion] = useState(0);
  const [busy, setBusy] = useState(false);
  const [settings, setSettings] = useState();

  useEffect(() => {
    setBusy(true);
    authContext
      .graphQLClient()
      .query({
        query: getRealmPropertyEmailQuery(),
      })
      .then((result) =>
        setSettings({
          customTemplate: JSON.parse(result.data.customTemplate),
          useCustomTemplate: JSON.parse(result.data.useCustomTemplate),
          useUserEmailAsFrom: JSON.parse(result.data.useUserEmailAsFrom),
          useUserEmailAsReplyTo: JSON.parse(result.data.useUserEmailAsReplyTo),
        })
      )
      .finally(() => setBusy(false));
  }, [authContext, version]);

  const onSendTestEmail = () => {
    setBusy(true);
    authContext
      .graphQLClient()
      .mutate({
        variables: {
          customTemplate: getValues('customTemplate'),
          useCustomTemplate: getValues('useCustomTemplate'),
          useUserEmailAsFrom: getValues('useUserEmailAsFrom'),
          useUserEmailAsReplyTo: getValues('useUserEmailAsReplyTo'),
        },
        mutation: getSendLinkMailTestMutation(),
      })
      .then((result) => {
        toast(`Email was sent to ${result.data.sendLinkMailTest}.`, {
          type: toast.TYPE.SUCCESS,
        });
      })
      .finally(() => setBusy(false));
  };

  const onSave = (data) => {
    setBusy(true);
    authContext
      .graphQLClient()
      .mutate({
        variables: {
          customTemplate: JSON.stringify(data.customTemplate),
          useCustomTemplate: JSON.stringify(data.useCustomTemplate),
          useUserEmailAsFrom: JSON.stringify(data.useUserEmailAsFrom),
          useUserEmailAsReplyTo: JSON.stringify(data.useUserEmailAsReplyTo),
        },
        mutation: setRealmPropertyEmailMutation(),
      })
      .then(() => {
        toast('Email parameters were saved.', { type: toast.TYPE.SUCCESS });
        setVersion(version + 1);
      })
      .finally(() => setBusy(false));
  };

  const onCancel = () => {
    setVersion(version + 1);
    reset();
  };

  return (
    <>
      <LoaderOverlay busy={busy} />
      <section data-testid="admin_email" className="column-stretch w100">
        {settings && (
          <form onSubmit={handleSubmit(onSave)}>
            <label htmlFor="settings">{t('settings')}</label>
            <div id="settings">
              <div>
                <input
                  type="checkbox"
                  name="useCustomTemplate"
                  ref={register()}
                  defaultChecked={settings.useCustomTemplate}
                />
                {t('settings.user.template')}
              </div>
              <div>
                <input
                  type="checkbox"
                  name="useUserEmailAsFrom"
                  ref={register()}
                  defaultChecked={settings.useUserEmailAsFrom}
                />
                {t('settings.user.email')}
              </div>
              <div>
                <input
                  type="checkbox"
                  name="useUserEmailAsReplyTo"
                  ref={register()}
                  defaultChecked={settings.useUserEmailAsReplyTo}
                />
                {t('settings.user.email.reply')}
              </div>
            </div>
            <label htmlFor="template">
              {t('settings.user.template.title')}
            </label>
            <textarea
              name="customTemplate"
              ref={register()}
              defaultValue={settings.customTemplate}
            />
            <div
              aria-hidden="true"
              role="button"
              className="clickable"
              onClick={onSendTestEmail}
            >
              <i className="icon-email icon-small clickable" />{' '}
              {t('settings.user.email.test')}
            </div>
            <div>
              <input
                name="save"
                type="submit"
                className="yr-button"
                value={t('button.save')}
              />
              <input
                name="cancel"
                type="button"
                onClick={onCancel}
                className="yr-button button-error"
                value={t('button.cancel')}
              />
            </div>
          </form>
        )}
      </section>
    </>
  );
};

export default Email;
