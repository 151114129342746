import React, { useMemo, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FeedbackTable } from './FeedbackTable';
import { AuthContext } from '../Authentication';
import { format } from '../../services/date-fns';
import {
  getCallFeedbackQuery,
  getFeedbackStatsQuery,
} from '../../services/Queries';
import './Feedback.scss';

export const Feedback = () => {
  const [loading, setLoading] = useState(true);
  const [limit, setLimit] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalFeedback, setTotalFeedback] = useState(0);
  const [averageFeedback, setAverageFeedback] = useState(0);
  const [feedbackData, setFeedbackData] = useState([]);

  const authContext = useContext(AuthContext);
  const { t } = useTranslation();

  useEffect(() => {
    if (authContext.isAuthenticated) {
      authContext
        .graphQLClient()
        .query({
          query: getFeedbackStatsQuery(),
        })
        .then(({ data }) => {
          const { stats } = data;
          setTotalFeedback(stats.totalCount);
          setAverageFeedback(stats.averageRating);
        });
    }
  }, [authContext]);

  useEffect(() => {
    if (authContext.isAuthenticated) {
      setLoading(true);
      authContext
        .graphQLClient()
        .query({
          query: getCallFeedbackQuery({ offset: limit * currentPage, limit }),
        })
        .then(({ data }) => {
          const { feedbacks } = data;
          setLoading(false);
          setFeedbackData(feedbacks.data);
          setPageCount(Math.ceil(feedbacks.totalCount / limit));
        });
    }
  }, [authContext, currentPage, limit]);

  const columns = useMemo(
    () => [
      {
        Header: t('feedback.column.roomId'),
        accessor: 'roomId',
        width: '27%',
      },
      {
        Header: t('feedback.column.date'),
        accessor: 'submittedAt',
        width: '15%',
        Cell: ({ cell }) => {
          return format(new Date(cell.value), 'P');
        },
      },
      {
        Header: t('feedback.column.rating'),
        accessor: 'rating',
        width: '8%',
      },
      {
        Header: t('feedback.column.message'),
        accessor: 'text',
        width: '50%',
      },
    ],
    [t]
  );

  const formatAverageFeedback = () => {
    if (averageFeedback % 1 !== 0) {
      return averageFeedback.toFixed(1);
    }

    return averageFeedback;
  };

  return (
    <div className="feedback-container">
      <div>
        <h3 className="title">{t('feedback.title')}</h3>
        <div className="card-container">
          <div className="card-item">
            <p className="card-title">{t('feedback.total.number')}</p>
            <p className="card-amount">{totalFeedback}</p>
          </div>
          <div className="card-item">
            <p className="card-title">{t('feedback.average.rating')}</p>
            <p className="card-amount">
              {averageFeedback ? `${formatAverageFeedback()}/5` : '--'}
            </p>
          </div>
        </div>
      </div>
      <FeedbackTable
        columns={columns}
        data={feedbackData}
        loading={loading}
        pageCount={pageCount}
        limit={limit}
        currentPage={currentPage}
        setLimit={setLimit}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
};
