import axios from 'axios';

import { getStateServerUrl } from '../utils';

const checkStatus = (url) =>
  new Promise((resolve, reject) => {
    axios
      .get(url)
      .then((response) => {
        if (response.status === 200) {
          resolve();
        } else {
          reject();
        }
      })
      .catch(() => {
        reject();
      });
  });

const checkContentServer = () => {
  const url = `${process.env.REACT_APP_API_URL}/actuator/health`;
  return checkStatus(url);
};

const checkStateServer = () => {
  const url = `${getStateServerUrl()}/health`;
  return checkStatus(url);
};

export { checkContentServer, checkStateServer };
