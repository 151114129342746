import { gql } from 'graphql-tag';
import uuid from './UUID';

const buildDocumentInput = (objectName, id, file) =>
  id && file
    ? `${objectName}: {
            id: "${id}"
            name: "${file.name}"
            mediaType: "${file.type}"
        }`
    : '';

/**
 * Gets all room types including all room type fields.
 */
const getRoomTypesQuery = () => gql`
  query {
    roomTypes {
      offset
      limit
      totalCount
      data {
        id
        name
        defaultRoomType
        screenSharingEnabled
      }
    }
  }
`;

const getAddRoomTypeMutation = (
  name,
  isDefaultRoomType,
  isScreenSharingEnable
) => {
  const input = `{
        id: "${uuid()}"
        name: "${name}"
        defaultRoomType: ${isDefaultRoomType}
        screenSharingEnabled: ${isScreenSharingEnable}
    }`;
  return gql`
        mutation {
            addRoomType(roomType: ${input}) {
                id
                name
                defaultRoomType
                screenSharingEnabled
            }
        }
    `;
};

const getUpdateRoomTypeMutation = (
  id,
  name,
  isDefaultRoomType,
  isScreenSharingEnable
) => {
  const input = `{
        id: "${id}"
        name: "${name}"
        defaultRoomType: ${isDefaultRoomType}
        screenSharingEnabled: ${isScreenSharingEnable}
    }`;
  return gql`
        mutation {
            updateRoomType(roomType: ${input}) {
                id
                name
                defaultRoomType
                screenSharingEnabled
            }
        }
    `;
};

const getDeleteRoomTypeMutation = (id) => gql`
        mutation {
            deleteRoomType(id: "${id}")
        }
    `;

/**
 * Get all media and room types combined.
 */
const getMediaAndRoomTypesQuery = () => gql`
  query {
    media {
      offset
      limit
      totalCount
      data {
        id
        name
        roomTypes
        contentDocument {
          id
          name
          mediaType
          downloadUrl
          uploadUrl
        }
        thumbnailDocument {
          id
          name
          mediaType
          downloadUrl
          uploadUrl
        }
      }
    }
    roomTypes {
      offset
      limit
      totalCount
      data {
        id
        name
      }
    }
  }
`;

/**
 * Get all media, forms, config data, and realm properties for the ysura session connector configuration combined.
 */
const getYsuraSessionConnectorQuery = () => gql`
  query {
    media {
      data {
        id
        name
      }
    }
    form {
      data {
        id
        name
      }
    }
    sessionConnectorConfigData {
      data
    }
    activityType: getRealmProperty(key: "YSURA_CONNECTOR_ACTIVITY_TYPE")
    topic: getRealmProperty(key: "YSURA_CONNECTOR_TOPIC")
    commentType: getRealmProperty(key: "YSURA_CONNECTOR_COMMENT_TYPE")
    mediaMapping: getRealmProperty(key: "YSURA_CONNECTOR_MEDIA_MAPPING")
    consentTypeMapping: getRealmProperty(
      key: "YSURA_CONNECTOR_CONSENT_TYPE_MAPPING"
    )
  }
`;

/**
 * Set all realm properties for the ysura session connector.
 */
const getYsuraSessionConnectorMutation = () => gql`
  mutation setYsuraSessionConnectorSettings(
    $activityType: String
    $topic: String
    $commentType: String
    $mediaMapping: String
    $consentTypeMapping: String
  ) {
    activityType: setRealmProperty(
      key: "YSURA_CONNECTOR_ACTIVITY_TYPE"
      value: $activityType
    )
    topic: setRealmProperty(key: "YSURA_CONNECTOR_TOPIC", value: $topic)
    commentType: setRealmProperty(
      key: "YSURA_CONNECTOR_COMMENT_TYPE"
      value: $commentType
    )
    mediaMapping: setRealmProperty(
      key: "YSURA_CONNECTOR_MEDIA_MAPPING"
      value: $mediaMapping
    )
    consentTypeMapping: setRealmProperty(
      key: "YSURA_CONNECTOR_CONSENT_TYPE_MAPPING"
      value: $consentTypeMapping
    )
  }
`;

/**
 * Gets a document by id.
 *
 * @param {*} id the document id (required)
 */
const getDocumentQuery = (id) => gql`
        query {
            document(id: "${id}") {
                id
                name
                mediaType
                downloadUrl
                uploadUrl
            }
        }`;

/**
 * Update an exsting media and return all media fields.
 *
 * @param {*} id the media id (required)
 * @param {*} name the name (optional)
 * @param {*} roomTypes the list of room type ids this media is assigned to (optional)
 * @param {*} thumbnailDocumentId the thumbnail document id (optional)
 * @param {*} thumbnailFile the thumbnail file (optional)
 * @param {*} contentDocumentId the content document id (optional)
 * @param {*} contentFile the content file (optional)
 */
const getUpdateMediaMutation = (
  id,
  name,
  roomTypes,
  thumbnailDocumentId,
  thumbnailFile,
  contentDocumentId,
  contentFile
) => {
  const thumbnailInput = buildDocumentInput(
    'thumbnailDocument',
    thumbnailDocumentId,
    thumbnailFile
  );
  const contentInput = buildDocumentInput(
    'contentDocument',
    contentDocumentId,
    contentFile
  );
  const input = `{
        id: "${id}"
        name: "${name}"
        roomTypes: ${JSON.stringify(roomTypes)}
        ${thumbnailInput}
        ${contentInput}
    }`;
  return gql`
        mutation {
            updateMedia(media: ${input}) {
                id
                name
                contentDocument {
                    id
                    name
                    mediaType
                    downloadUrl
                    uploadUrl
                }
                thumbnailDocument {
                    id
                    name
                    mediaType
                    downloadUrl
                    uploadUrl
                }
            }
        }
    `;
};

const getDeleteMediaMutation = (id) => gql`
        mutation {
            deleteMedia(id: "${id}")
        }
    `;

/**
 * Add a new media and return all media fields. All required ids are
 * generated.
 *
 * @param {*} name the name (required)
 * @param {*} roomTypes the list of room type ids this media is assigned to (required)
 * @param {*} thumbnailFile the thumbnail file (required)
 * @param {*} contentFile the content file (required)
 */
const getAddMediaMutation = (name, roomTypes, thumbnailFile, contentFile) => {
  const thumbnailInput = buildDocumentInput(
    'thumbnailDocument',
    uuid(),
    thumbnailFile
  );
  const contentInput = buildDocumentInput(
    'contentDocument',
    uuid(),
    contentFile
  );
  const input = `{
        id: "${uuid()}"
        name: "${name}"
        roomTypes: ${JSON.stringify(roomTypes)}
        ${thumbnailInput}
        ${contentInput}
    }`;
  return gql`
        mutation {
            addMedia(media: ${input}) {
                id
                name
                contentDocument {
                    id
                    name
                    mediaType
                    downloadUrl
                    uploadUrl
                }
                thumbnailDocument {
                    id
                    name
                    mediaType
                    downloadUrl
                    uploadUrl
                }
            }
        }
    `;
};

/**
 * Sent a test email to the current user.
 */
const getSendLinkMailTestMutation = () => gql`
  mutation sendLinkMailTestsendLinkMailTest(
    $customTemplate: String!
    $useCustomTemplate: Boolean!
    $useUserEmailAsFrom: Boolean!
    $useUserEmailAsReplyTo: Boolean!
  ) {
    sendLinkMailTest(
      customTemplate: $customTemplate
      useCustomTemplate: $useCustomTemplate
      useUserEmailAsFrom: $useUserEmailAsFrom
      useUserEmailAsReplyTo: $useUserEmailAsReplyTo
    )
  }
`;

/**
 * Get realm properties for the Email administration.
 */
const getRealmPropertyEmailQuery = () => gql`
  query {
    useCustomTemplate: getRealmProperty(key: "MAIL_USE_CUSTOM_TEMPLATE")
    useUserEmailAsFrom: getRealmProperty(key: "MAIL_USE_USER_EMAIL_AS_FROM")
    useUserEmailAsReplyTo: getRealmProperty(
      key: "MAIL_USE_USER_EMAIL_AS_REPLY_TO"
    )
    customTemplate: getRealmProperty(key: "MAIL_CUSTOM_TEMPLATE")
  }
`;

/**
 * Set realm properties for the Email administration.
 */
const setRealmPropertyEmailMutation = () => gql`
  mutation setRealmProperties(
    $useCustomTemplate: String!
    $useUserEmailAsFrom: String!
    $useUserEmailAsReplyTo: String!
    $customTemplate: String
  ) {
    useCustomTemplate: setRealmProperty(
      key: "MAIL_USE_CUSTOM_TEMPLATE"
      value: $useCustomTemplate
    )
    useUserEmailAsFrom: setRealmProperty(
      key: "MAIL_USE_USER_EMAIL_AS_FROM"
      value: $useUserEmailAsFrom
    )
    useUserEmailAsReplyTo: setRealmProperty(
      key: "MAIL_USE_USER_EMAIL_AS_REPLY_TO"
      value: $useUserEmailAsReplyTo
    )
    customTemplate: setRealmProperty(
      key: "MAIL_CUSTOM_TEMPLATE"
      value: $customTemplate
    )
  }
`;

/**
 * Get realm properties for the session connector administration.
 */
const getRealmPropertySessionConnectorQuery = () => gql`
  query {
    sessionConnector: getRealmProperty(key: "SESSION_CONNECTOR_TYPE")
  }
`;

/**
 * Form administration.
 */
const getFormAndRoomTypesQuery = () => gql`
  query {
    form {
      offset
      limit
      totalCount
      data {
        id
        name
        template
        roomTypes
      }
    }
    roomTypes {
      offset
      limit
      totalCount
      data {
        id
        name
      }
    }
  }
`;

/**
 * Add a new form.
 */
const getAddFormMutation = () => gql`
  mutation addFormFunction($form: FormAddInput!) {
    addForm(form: $form) {
      id
      name
      template
      roomTypes
    }
  }
`;

/**
 * Update a existing form.
 */
const getUpdateFormMutation = () => gql`
  mutation updateFormFunction($form: FormUpdateInput!) {
    updateForm(form: $form) {
      id
      name
      template
      roomTypes
    }
  }
`;

/**
 * Delete a existing form.
 */
const getDeleteFormMutation = (id) => gql`
        mutation {
            deleteForm(id: "${id}")
        }
    `;

/**
 * Start the synchronisation from the upstream system.
 */
const getSyncMutation = () => gql`
  mutation {
    sync
  }
`;

/**
 * Get realm configuration query
 */
const getRealmConfigurationQuery = () => gql`
  query {
    getRealmConfiguration {
      id
      upstream
    }
  }
`;

/**
 * Gets call feedback data for the feedback table
 */
const getCallFeedbackQuery = ({ offset, limit }) => gql`
  query {
    feedbacks(offset: ${offset}, limit: ${limit}) {
      offset
      limit
      totalCount
      data {
        submittedAt
        roomId
        rating
        text
      }
    }
  }
`;

/**
 * Gets total & average data for all given feedback
 */
const getFeedbackStatsQuery = () => gql`
  query {
    stats {
      totalCount
      averageRating
    }
  }
`;

export {
  getRoomTypesQuery,
  getAddRoomTypeMutation,
  getUpdateRoomTypeMutation,
  getDeleteRoomTypeMutation,
  getMediaAndRoomTypesQuery,
  getYsuraSessionConnectorQuery,
  getYsuraSessionConnectorMutation,
  getDocumentQuery,
  getFormAndRoomTypesQuery,
  getAddFormMutation,
  getUpdateFormMutation,
  getDeleteFormMutation,
  getUpdateMediaMutation,
  getDeleteMediaMutation,
  getAddMediaMutation,
  getSendLinkMailTestMutation,
  getRealmPropertyEmailQuery,
  setRealmPropertyEmailMutation,
  getRealmPropertySessionConnectorQuery,
  getSyncMutation,
  getRealmConfigurationQuery,
  getCallFeedbackQuery,
  getFeedbackStatsQuery,
};
