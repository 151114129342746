import React, { createContext, useCallback, useEffect, useState } from 'react';
import Keycloak from 'keycloak-js';
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { onError } from 'apollo-link-error';
import { toast } from 'react-toastify';
import i18n from 'i18next';
import { node } from 'prop-types';
import { getKeycloakRealm, getRealm } from '../utils';

export const AuthContext = createContext();

export const AuthProvider = ({ ...props }) => {
  const [authContext, setAuthContext] = useState({
    isAuthenticated: false,
  });

  const createApolloClient = useCallback((adapter, realm) => {
    const defaultOptions = {
      watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
    };

    const httpLink = createHttpLink({
      uri: `${process.env.REACT_APP_API_URL}/graphql`,
    });

    const authLink = setContext((_, { headers }) => ({
      headers: {
        ...headers,
        Authorization: `Bearer ${adapter.token}`,
        'Accept-Language': i18n.language.split('-')[0],
        'X-Ysura-Realm': realm,
      },
    }));

    const errorLink = onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach((error) => {
          if (error.extensions && error.extensions.userMessage) {
            toast.error(error.extensions.userMessage, { autoClose: false });
          } else {
            toast.error(i18n.t('generalError'), { autoClose: false });
          }
        });
      }
      if (networkError) {
        toast.error(`${i18n.t('generalNetworkError')} ${networkError}`, {
          autoClose: false,
        });
      }
    });

    return new ApolloClient({
      link: ApolloLink.from([errorLink, authLink, httpLink]),
      cache: new InMemoryCache(),
      defaultOptions,
    });
  }, []);

  const updateContext = useCallback(
    (keycloak, realm, keycloakRealm) => {
      setAuthContext({
        realm,
        keycloakRealm,
        isAuthenticated: true,
        token: keycloak.token,
        tokenParsed: keycloak.tokenParsed,
        logout: keycloak.createLogoutUrl({
          redirectUri: window.location.origin,
        }),
        graphQLClient: () => createApolloClient(keycloak, realm),
      });
    },
    [createApolloClient]
  );

  useEffect(() => {
    const realm = getRealm();
    const keycloakRealm = getKeycloakRealm();

    const keycloak = new Keycloak({
      realm: keycloakRealm,
      url: process.env.REACT_APP_AUTH_URL,
      clientId: 'yr-admin-ui',
    });

    // Enable auto refresh of access token
    keycloak.onTokenExpired = () =>
      keycloak
        .updateToken(5)
        .then((refreshed) => {
          if (refreshed) {
            console.log('[KEYCLOAK.onTokenExpired] Token refreshed.');
            updateContext(keycloak, realm, keycloakRealm);
          } else {
            console.log('[KEYCLOAK.onTokenExpired] Token is still valid.');
          }
        })
        .catch(() => {
          console.log(
            '[KEYCLOAK.onTokenExpired] Failed to refresh the token, or the session has expired.'
          );
        });

    // Init Keycloak adapter
    keycloak
      .init({})
      .then((authenticated) => {
        if (!authenticated) {
          keycloak.login();
        }
        if (!keycloak.tokenParsed.realm_access.roles.includes('admin')) {
          // only allow admins to access the app
          return;
        }
        updateContext(keycloak, realm, keycloakRealm);
      })
      .catch((e) => {
        console.error(e);
      });
  }, [updateContext]);

  return (
    <AuthContext.Provider value={authContext}>
      {props.children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: node.isRequired,
};
