import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { AuthContext } from './Authentication';
import LoaderOverlay from './LoaderOverlay';
import {
  getAddFormMutation,
  getDeleteFormMutation,
  getFormAndRoomTypesQuery,
  getUpdateFormMutation,
} from '../services/Queries';
import DeleteDialog from './DeleteDialog';
import uuid from '../services/UUID';

const Form = () => {
  const authContext = useContext(AuthContext);
  const { register, handleSubmit, reset, errors } = useForm();

  const { t } = useTranslation();

  const [version, setVersion] = useState(0);
  const [busy, setBusy] = useState(false);

  const [forms, setForms] = useState([]);
  const [roomTypes, setRoomTypes] = useState([]);
  const [selectedForm, setSelectedForm] = useState();

  const [deleteDialog, setDeleteDialog] = useState(false);

  useEffect(() => {
    setBusy(true);
    authContext
      .graphQLClient()
      .query({
        query: getFormAndRoomTypesQuery(),
      })
      .then((result) => {
        setForms(result.data.form.data);
        setRoomTypes(result.data.roomTypes.data);
      })
      .finally(() => setBusy(false));
  }, [version, authContext]);

  const onFormSelected = (it) => {
    setSelectedForm(it);
    reset();
  };

  const onCreateForm = () => {
    setSelectedForm({});
    reset();
  };

  const onSave = (data) => {
    const selectedRoomTypes = roomTypes
      .filter((it) => data[it.id])
      .map((it) => it.id);

    if (selectedForm.id) {
      authContext
        .graphQLClient()
        .mutate({
          variables: {
            form: {
              id: selectedForm.id,
              name: data.name,
              template: data.template,
              roomTypes: selectedRoomTypes,
            },
          },
          mutation: getUpdateFormMutation(),
        })
        .then(() => {
          toast(t('form.message.save'), { type: toast.TYPE.SUCCESS });
          setVersion(version + 1);
          setSelectedForm(undefined);
        })
        .finally(() => setBusy(false));
    } else {
      authContext
        .graphQLClient()
        .mutate({
          variables: {
            form: {
              id: uuid(),
              name: data.name,
              template: data.template,
              roomTypes: selectedRoomTypes,
            },
          },
          mutation: getAddFormMutation(),
        })
        .then(() => {
          setVersion(version + 1);
          setSelectedForm(undefined);
        })
        .finally(() => setBusy(false));
    }
  };

  const onCancel = () => {
    if (!selectedForm.id) {
      setSelectedForm(undefined);
      reset();
    } else {
      onFormSelected(selectedForm);
      reset();
    }
  };

  const toggleDeleteDialog = () => {
    setDeleteDialog(!deleteDialog);
  };

  const onDelete = () => {
    if (selectedForm.id) {
      const mutation = getDeleteFormMutation(selectedForm.id);
      authContext
        .graphQLClient()
        .mutate({
          mutation,
        })
        .then((result) => {
          if (result.data && result.data.deleteForm) {
            toast(t('form.message.delete'), { type: toast.TYPE.SUCCESS });
            setVersion(version + 1);
            setSelectedForm(undefined);
          } else {
            toast(t('form.message.delete.error'), { type: toast.TYPE.ERROR });
          }
        })
        .finally(() => setBusy(false));
      setDeleteDialog(false);
    }
  };

  const renderFormItem = (it) => (
    <div
      key={it.id}
      aria-hidden="true"
      role="button"
      className="list-item list-item-with-shadow"
      onClick={() => onFormSelected(it)}
    >
      {selectedForm && selectedForm.id === it.id && (
        <div className="list-item-selected" />
      )}
      <div className="list-item-title">{it.name}</div>
    </div>
  );

  const renderFormList = () => (
    <section data-testid="admin_form" className="column-stretch h100">
      <div className="list-item">
        <div
          aria-hidden="true"
          role="button"
          className="list-item-title clickable"
          onClick={onCreateForm}
        >
          <i className="icon-circled-plus icon-small clickable" />{' '}
          {t('form.add.new')}
        </div>
      </div>
      <div className="overflow-y-auto fix-margin-shadow">
        {forms.map(renderFormItem)}
      </div>
    </section>
  );

  const renderRoomTypeItem = (it) => {
    const { id } = it;
    const checked =
      selectedForm.roomTypes && selectedForm.roomTypes.includes(id);
    return (
      <div key={id}>
        <input
          name={id}
          type="checkbox"
          defaultChecked={checked}
          ref={register}
        />
        {it.name}
      </div>
    );
  };

  const renderFormEditor = () => (
    <section className="column-stretch w100">
      <form onSubmit={handleSubmit(onSave)}>
        <label htmlFor="name">{t('common.name')}</label>
        <div id="name">
          <input
            name="name"
            type="text"
            defaultValue={selectedForm.name || ''}
            ref={register({ required: true })}
          />
          {errors.name && (
            <span className="error-desc">{t('common.name.error')}</span>
          )}
        </div>

        <div>&nbsp;</div>

        <label htmlFor="template">{t('form.template.title')}</label>

        <div id="template">
          <textarea
            name="template"
            style={{ whiteSpace: 'pre-wrap' }}
            defaultValue={selectedForm.template}
            ref={register({ required: true })}
          />
          {errors.template && (
            <span className="error-desc">{t('form.template.error')}</span>
          )}
        </div>

        <div>&nbsp;</div>

        <label htmlFor="roomTypes">{t('media.roomType.title')}</label>
        <div id="roomTypes">{roomTypes.map(renderRoomTypeItem)}</div>

        <div>
          <input
            name="save"
            type="submit"
            className="yr-button"
            value={t('button.save')}
          />
          <input
            name="cancel"
            type="button"
            onClick={onCancel}
            className="yr-button button-error"
            value={t('button.cancel')}
          />
          {selectedForm.id && (
            <input
              name="delete"
              type="button"
              onClick={toggleDeleteDialog}
              className="yr-button button-error"
              value={t('button.delete')}
            />
          )}
        </div>
      </form>
      {deleteDialog && (
        <DeleteDialog
          contentKey="form.message.delete.confirmation"
          onDelete={onDelete}
          onClose={toggleDeleteDialog}
        />
      )}
    </section>
  );

  return (
    <>
      <LoaderOverlay busy={busy} />
      {forms && renderFormList()}
      {selectedForm && renderFormEditor()}
    </>
  );
};

export default Form;
