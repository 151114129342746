import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { AuthContext } from './Authentication';
import LoaderOverlay from './LoaderOverlay';
import {
  getAddRoomTypeMutation,
  getDeleteRoomTypeMutation,
  getRoomTypesQuery,
  getUpdateRoomTypeMutation,
} from '../services/Queries';
import DeleteDialog from './DeleteDialog';

const RoomType = () => {
  const authContext = useContext(AuthContext);
  const { register, handleSubmit, reset, errors } = useForm();

  const { t } = useTranslation();

  const [version, setVersion] = useState(0);
  const [busy, setBusy] = useState(false);
  const [roomTypes, setRoomTypes] = useState(false);
  const [selectedRoomType, setSelectedRoomType] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);

  useEffect(() => {
    setBusy(true);
    authContext
      .graphQLClient()
      .query({
        query: getRoomTypesQuery(),
      })
      .then((result) => {
        setRoomTypes(result.data.roomTypes.data);
      })
      .finally(() => setBusy(false));
  }, [version, authContext]);

  const onRoomTypeSelected = (it) => {
    setSelectedRoomType(it);
    reset();
  };

  const onCreateRoomType = () => {
    setSelectedRoomType({});
    reset();
  };

  const onSave = (data) => {
    const selectedName = data.name;
    const isDefaultRoomType = data.defaultRoomType;
    const isScreenSharingEnable = data.screenSharingEnabled;

    const existingDefaultType = roomTypes
      .filter((it) => it.id !== selectedRoomType.id || !selectedRoomType.id)
      .some((it) => it.defaultRoomType);

    if (isDefaultRoomType && existingDefaultType) {
      toast(t('roomType.defaultType.error'), { type: toast.TYPE.ERROR });
      return;
    }

    if (selectedRoomType.id) {
      const mutation = getUpdateRoomTypeMutation(
        selectedRoomType.id,
        selectedName,
        isDefaultRoomType,
        isScreenSharingEnable
      );
      authContext
        .graphQLClient()
        .mutate({
          mutation,
        })
        .then(() => {
          toast(t('roomType.message.save'), { type: toast.TYPE.SUCCESS });
          setVersion(version + 1);
          setSelectedRoomType(undefined);
        })
        .finally(() => setBusy(false));
    } else {
      const mutation = getAddRoomTypeMutation(
        selectedName,
        isDefaultRoomType,
        isScreenSharingEnable
      );
      authContext
        .graphQLClient()
        .mutate({
          mutation,
        })
        .then(() => {
          setVersion(version + 1);
          setSelectedRoomType(undefined);
        })
        .finally(() => setBusy(false));
    }
  };

  const onCancel = () => {
    if (!selectedRoomType.id) {
      setSelectedRoomType(undefined);
      reset();
    } else {
      onRoomTypeSelected(selectedRoomType);
      reset();
    }
  };

  const toggleDeleteDialog = () => {
    setDeleteDialog(!deleteDialog);
  };

  const onDelete = () => {
    if (selectedRoomType.id) {
      const mutation = getDeleteRoomTypeMutation(selectedRoomType.id);
      authContext
        .graphQLClient()
        .mutate({
          mutation,
        })
        .then((result) => {
          if (result.data && result.data.deleteRoomType) {
            toast(t('roomType.message.delete'), { type: toast.TYPE.SUCCESS });
            setVersion(version + 1);
            setSelectedRoomType(undefined);
          } else {
            toast(t('roomType.message.delete.error'), {
              type: toast.TYPE.ERROR,
            });
          }
        })
        .finally(() => setBusy(false));
      setDeleteDialog(false);
    }
  };

  const renderRoomTypeItem = (it) => (
    <div
      key={it.id}
      aria-hidden="true"
      role="button"
      className="list-item list-item-with-shadow"
      onClick={() => onRoomTypeSelected(it)}
    >
      {selectedRoomType && selectedRoomType.id === it.id && (
        <div className="list-item-selected" />
      )}
      <div className="list-item-title">{it.name}</div>
    </div>
  );

  const renderRoomTypeList = () => (
    <section data-testid="admin_room" className="column-stretch h100">
      <div className="list-item">
        <div
          className="list-item-title clickable"
          aria-hidden="true"
          role="button"
          onClick={onCreateRoomType}
        >
          <i className="icon-circled-plus icon-small clickable" />{' '}
          {t('roomType.add.new')}
        </div>
      </div>
      <div className="overflow-y-auto fix-margin-shadow">
        {roomTypes.map(renderRoomTypeItem)}
      </div>
    </section>
  );

  const renderRoomTypeEditor = () => (
    <section className="column-stretch w100">
      <form onSubmit={handleSubmit(onSave)}>
        <label htmlFor="name">{t('common.name')}</label>
        <div id="name">
          <input
            name="name"
            type="text"
            defaultValue={selectedRoomType.name || ''}
            ref={register({ required: true })}
          />
          {errors.name && (
            <span className="error-desc">{t('common.name.error')}</span>
          )}
        </div>

        <div>
          <input
            name="defaultRoomType"
            type="checkbox"
            defaultChecked={selectedRoomType.defaultRoomType}
            ref={register({ require: true })}
          />
          <span>{t('roomType.defaultType.title')}</span>
        </div>

        <div>
          <input
            name="screenSharingEnabled"
            type="checkbox"
            defaultChecked={selectedRoomType.screenSharingEnabled}
            ref={register({ require: true })}
          />
          <span>{t('roomType.screenSharingEnable.title')}</span>
        </div>

        <div>
          <input
            name="save"
            type="submit"
            className="yr-button"
            value={t('button.save')}
          />
          <input
            name="cancel"
            type="button"
            onClick={onCancel}
            className="yr-button button-error"
            value={t('button.cancel')}
          />
          {selectedRoomType.id && (
            <input
              name="delete"
              type="button"
              onClick={toggleDeleteDialog}
              className="yr-button button-error"
              value={t('button.delete')}
            />
          )}
        </div>
      </form>
      {deleteDialog && (
        <DeleteDialog
          contentKey="roomType.message.delete.confirmation"
          onDelete={onDelete}
          onClose={toggleDeleteDialog}
        />
      )}
    </section>
  );

  return (
    <>
      <LoaderOverlay busy={busy} />
      {roomTypes && renderRoomTypeList()}
      {selectedRoomType && renderRoomTypeEditor()}
    </>
  );
};

export default RoomType;
