import React, { useState } from 'react';
import Modal from 'react-responsive-modal';
import { useTranslation } from 'react-i18next';
import './DeleteDialog.scss';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const DateTimePicker = ({ dateValue, setDateValue, interval }) => {
  const { t } = useTranslation();

  return (
    <DatePicker
      selected={dateValue}
      onChange={(date) => setDateValue(date)}
      showTimeSelect
      timeFormat="HH:mm"
      timeIntervals={interval}
      timeCaption={t('common.time')}
      dateFormat="MMMM d, yyyy HH:mm"
    />
  );
};

const DateTimePickDialog = ({ title, interval, onSave, onClose }) => {
  const [open, setOpen] = useState(true);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const { t } = useTranslation();

  const onConfirm = () => {
    setOpen(false);
    if (onSave) {
      onSave(startDate, endDate);
    }
  };

  const onCancel = () => {
    setOpen(false);
    if (onClose) {
      onClose();
    }
  };

  const inValidateTimeInput = () =>
    startDate && endDate && new Date(startDate) >= new Date(endDate);

  return (
    <Modal
      open={open}
      center
      onClose={onCancel}
      classNames={{
        overlay: 'customOverlay',
        modal: 'customModal',
        closeButton: 'customCloseButton',
      }}
    >
      <div>
        <div>
          <table>
            <thead>
              <tr>
                <th colSpan={3}>
                  <span>{t(title)}</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <DateTimePicker
                    setDateValue={setStartDate}
                    dateValue={startDate}
                    interval={interval}
                  />
                </td>
                <td>
                  <span>&nbsp;{t('common.to')}&nbsp;</span>
                </td>
                <td>
                  <DateTimePicker
                    setDateValue={setEndDate}
                    dateValue={endDate}
                    interval={interval}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div>
          <input
            name="save"
            type="submit"
            disabled={inValidateTimeInput()}
            onClick={onConfirm}
            className="yr-button"
            value={t('button.save')}
          />
          <input
            id="delete"
            name="cancel"
            type="button"
            onClick={onCancel}
            className="yr-button"
            value={t('button.cancel')}
          />
        </div>
      </div>
    </Modal>
  );
};

export default DateTimePickDialog;
