import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { checkContentServer, checkStateServer } from '../services/ServiceCheck';
import './Dashboard.scss';

const Dashboard = () => {
  const [contentServerUp, setContentServerUp] = useState();
  const [stateServerUp, setStateServerUp] = useState();

  const { t } = useTranslation();

  useEffect(() => {
    checkContentServer()
      .then(() => setContentServerUp(true))
      .catch(() => setContentServerUp(false));
    checkStateServer()
      .then(() => setStateServerUp(true))
      .catch(() => setStateServerUp(false));
  }, []);

  const getClasses = (status) => {
    if (status === true) {
      return 'status-bar-item up';
    }
    if (status === false) {
      return 'status-bar-item down';
    }

    return 'status-bar-item checking';
  };

  return (
    <section data-testid="admin_dashboard" className="column-stretch w100">
      <div data-testid="status_panel" className="status-bar">
        <h1>
          <i className="icon-admin icon-small" />
          {t('dashboard.title')}
        </h1>
        <hr />
        {t('dashboard.systemStatus.title')}
        <div
          data-testid="content_server_status"
          className={getClasses(contentServerUp)}
        >
          <i className="icon-documents" />
          <br />
          {t('dashboard.systemStatus.contentServer')}
        </div>
        <div
          data-testid="state_server_status"
          className={getClasses(stateServerUp)}
        >
          <i className="icon-headset" />
          <br />
          {t('dashboard.systemStatus.stateServer')}
        </div>
      </div>
    </section>
  );
};

export default Dashboard;
