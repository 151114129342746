/**
 * Generates a new UUID v4 (adapted from https://gist.github.com/jed/982883).
 */
/* eslint-disable */
const uuid = () =>
  ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
/* eslint-enable */
export default uuid;
