import React, { useState } from 'react';
import logoVertical from '../images/logo-vertical.png';
import './SideBarMenu.scss';

const SideBarMenu = ({ onItemSelected }) => {
  const [selectedItem, setSelectedItem] = useState('dashboard');

  const handleItemSelected = (item) => {
    setSelectedItem(item);
    onItemSelected(item);
  };

  const getClass = (item) =>
    item === selectedItem ? 'menu-icon menu-icon-selected' : 'menu-icon';

  return (
    <div className="side-bar-menu column-center">
      <div
        data-testid="admin_menu"
        className="side-bar-menu-top column-center priority"
      >
        <div data-testid="menu_logo" className="logo-vertical">
          <img src={logoVertical} alt="ysura" />
        </div>
        <button
          data-testid="menu_dashboard"
          type="button"
          className={getClass('dashboard')}
          onClick={() => handleItemSelected('dashboard')}
        >
          <i className="icon-home" />
        </button>
        <button
          data-testid="menu_media"
          type="button"
          className={getClass('media')}
          onClick={() => handleItemSelected('media')}
        >
          <i className="icon-media" />
        </button>
        <button
          data-testid="menu_form"
          type="button"
          className={getClass('form')}
          onClick={() => handleItemSelected('form')}
        >
          <i className="icon-consent" />
        </button>
        <button
          data-testid="menu_room"
          type="button"
          className={getClass('roomType')}
          onClick={() => handleItemSelected('roomType')}
        >
          <i className="icon-inventory" />
        </button>
        <button
          data-testid="menu_email"
          type="button"
          className={getClass('email')}
          onClick={() => handleItemSelected('email')}
        >
          <i className="icon-email " />
        </button>
        <button
          data-testid="menu_user"
          type="button"
          className={getClass('user')}
          onClick={() => handleItemSelected('user')}
        >
          <i className="icon-user " />
        </button>
        <button
          data-testid="menu_export"
          type="button"
          className={getClass('export')}
          onClick={() => handleItemSelected('export')}
        >
          <i className="icon-export" />
        </button>
        <button
          data-testid="menu_feedback"
          type="button"
          className={getClass('feedback')}
          onClick={() => handleItemSelected('feedback')}
        >
          <i className="icon-info-circle" />
        </button>
        <button
          data-testid="menu_session_connector"
          type="button"
          className={getClass('sessionConnector')}
          onClick={() => handleItemSelected('sessionConnector')}
        >
          <i className="icon-connector" />
        </button>
        <button
          data-testid="menu_sync"
          type="button"
          className={getClass('sync')}
          onClick={() => handleItemSelected('sync')}
        >
          <i className="icon-checked-cloud" />
        </button>
        <button
          data-testid="menu_language"
          type="button"
          className={getClass('admin')}
          onClick={() => handleItemSelected('admin')}
        >
          <i className="icon-setting" />
        </button>
      </div>
    </div>
  );
};

export default SideBarMenu;
