import axios from 'axios';
import { getDocumentQuery } from './Queries';

/**
 * Uploads a file to S3.
 *
 * First the document is queried to get a fresh signed S3 uplaod URL. This
 * URL is used to put the file to.
 *
 * @param {*} documentId the document id (required)
 * @param {*} file the file (required)
 * @param {*} authContext the auth context (required)
 */
const uploadFile = (documentId, file, authContext) =>
  authContext
    .graphQLClient()
    .query({
      query: getDocumentQuery(documentId),
    })
    .then((result) => {
      const url = result.data.document.uploadUrl;
      const options = {
        headers: {
          'Content-Type': file.type,
        },
      };
      return axios.put(url, file, options);
    });

export default uploadFile;
