import { func } from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-responsive-modal';
import { Select } from './Select';
import './DeleteDialog.scss';

const options = [
  { value: 'en', label: 'English' },
  { value: 'de', label: 'Deutsch' },
];

const LanguageSetting = ({ onClose }) => {
  const [open, setOpen] = useState(true);

  const { t, i18n } = useTranslation();

  const handleClose = () => {
    setOpen(false);
    if (onClose) {
      onClose();
    }
  };

  const handleLocaleChange = (event) => {
    i18n.changeLanguage(event);
  };

  const getSelectedLocale = () => i18n.language.split('-')[0];

  return (
    <Modal
      open={open}
      center
      onClose={handleClose}
      classNames={{
        overlay: 'customOverlay',
        modal: 'customModal',
        closeButton: 'customCloseButton',
      }}
    >
      <table
        data-testid="admin_language"
        className="side-bar-menu-settings-table"
      >
        <tbody>
          <tr>
            <td colSpan="2">
              <b>
                <span>{t('settings.language.title')}</span>
              </b>
            </td>
          </tr>
          <tr>
            <td>
              <span>{t('settings.language')}</span>
            </td>
            <td>
              <Select
                options={options}
                selectedValue={getSelectedLocale()}
                onChange={handleLocaleChange}
              />
            </td>
          </tr>
        </tbody>
      </table>
      <br />
    </Modal>
  );
};

LanguageSetting.propTypes = {
  onClose: func.isRequired,
};

export default LanguageSetting;
